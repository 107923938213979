import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../Image/logo.svg";
import user from "../Image/user.png";
import dropicon from "../Image/header-drop-icon.svg";
import useGetInfo from "../hook/useGetInfo";
import { useCookies } from "react-cookie";

const Header = () => {
  const [cookie, _, removeCookie] = useCookies([
    "user",
    "role",
    "companyImage",
    "companyName",
  ]);
  document.title = cookie.companyName;
  const { User } = useGetInfo();

  return (
    <div>
      <div className="fixx">
        <nav className="top-header navbar navbar-expand-sm sticky-top navbar-light">
          <div className="p-0 container-fluid">
            <Link to={"/CompanyProfile"}>
              <div className="header-logo-text">
                <div className="sidebar-logo w-50 gap-0">
                  <img
                    src={cookie?.companyImage}
                    onError={(e) => (e.currentTarget.src = logo)}
                    alt=""
                    className="img-fluid border"
                    style={{
                      width: "2.5vw",
                      height: "2.5vw",
                      borderRadius: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h4>{cookie.companyName}</h4>
              </div>
            </Link>
            <button
              className="navbar-toggler position-relative "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navheight justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav navbarbottom mb-lg-0 align-items-lg-center">
                <li className="ms-0 nav-item dropdown-notifications navbar-dropdown dropdown">
                  <Link className="nav-link hide-arrow" to={"/Notification"}>
                    <NavLink
                      className="nav-link bell position-relative "
                      aria-current="page"
                      to={"/Notification"}
                    >
                      {User?.notification > 0 && (
                        <span className="pulse"></span>
                      )}
                      <i class="fa-solid fa-bell"></i>
                    </NavLink>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end py-0"
                    data-bs-popper="static"
                  >
                    <li className="dropdown-menu-header border-bottom">
                      <div className="dropdown-header d-flex align-items-center py-3">
                        <h5 className="text-body mb-0 me-auto">Notification</h5>
                        <Link
                          to={"#"}
                          className="dropdown-notifications-all text-body"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          aria-label="Mark all as read"
                          data-bs-original-title="Mark all as read"
                        >
                          <i className="fa-regular fa-envelope-open"></i>
                        </Link>
                      </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container ps ps--active-y">
                      <ul className="list-group list-group-flush all-project-table">
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                              <p className="mb-0">
                                Won the monthly best seller gold badge
                              </p>
                              <small className="text-muted">1h ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Charles Franklin</h6>
                              <p className="mb-0">Accepted your connection</p>
                              <small className="text-muted">12hr ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">New Message ✉️</h6>
                              <p className="mb-0">
                                You have new message from Natalie
                              </p>
                              <small className="text-muted">1h ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">
                                Whoo! You have new order 🛒{" "}
                              </h6>
                              <p className="mb-0">
                                ACME Inc. made new order $1,154
                              </p>
                              <small className="text-muted">1 day ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">
                                Application has been approved 🚀{" "}
                              </h6>
                              <p className="mb-0">
                                Your ABC project application has been approved.
                              </p>
                              <small className="text-muted">2 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">
                                Monthly report is generated
                              </h6>
                              <p className="mb-0">
                                July monthly financial report is generated{" "}
                              </p>
                              <small className="text-muted">3 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Send connection request</h6>
                              <p className="mb-0">
                                Peter sent you connection request
                              </p>
                              <small className="text-muted">4 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">New message from Jane</h6>
                              <p className="mb-0">
                                Your have new message from Jane
                              </p>
                              <small className="text-muted">5 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img
                                  src={user}
                                  className="w-px-40 h-auto rounded-circle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">CPU is running high</h6>
                              <p className="mb-0">
                                CPU Utilization Percent is currently at 88.63%,
                              </p>
                              <small className="text-muted">5 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <Link
                                to={"#"}
                                className="dropdown-notifications-read"
                              >
                                <span className="badge badge-dot"></span>
                              </Link>
                              <Link
                                to={"#"}
                                className="dropdown-notifications-archive"
                              >
                                <span className="bx bx-x"></span>
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown-menu-footer border-top p-3">
                      <button className="btn add-new text-uppercase w-100">
                        view all notifications
                      </button>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <div className="user-img-name">
                    <div className="user-name-id">
                      <h5>{User?.name}</h5>
                      <p>ID-{User?.mainId}</p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="navbar-user position-relative border-0 bg-transparent"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={User?.image || user}
                          onError={(e) => (e.currentTarget.src = user)}
                          alt=""
                        />
                        <span className="">
                          <img
                            src={dropicon}
                            className="img-fluid w-auto h-auto position-absolute bottom-0 start-100 translate-middlee border-light rounded-circle"
                            alt=""
                          />
                        </span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end header-drop">
                        <Link
                          className="dropdown-item view-profile-drop"
                          to="/Profile"
                        >
                          <span key="t-logout">
                            <i class="fa-solid fa-user"></i> View Profile
                          </span>
                        </Link>
                        <Link
                          className="dropdown-item change-pass-drop"
                          to="/ChangePassword"
                        >
                          <span key="t-logout">
                            <i class="fa-solid fa-lock"></i> Change Password
                          </span>
                        </Link>
                        <Link
                          onClick={() => {
                            removeCookie("user");
                            removeCookie("role");
                            removeCookie("companyImage");
                            removeCookie("companyName");
                          }}
                          className="dropdown-item logout-drop"
                          to="/Signin"
                        >
                          <span key="t-logout">
                            <i class="fa-solid fa-power-off"></i> LogOut
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
