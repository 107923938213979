import axios from "axios";

export const GETAPI = (api, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = token
        ? {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        : {};
      const { data } = await axios.get(api, header);
      if (data.status == "error") reject(data.message);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const POSTAPI = (api, obj = {}, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = token
        ? {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        : {};
      const { data } = await axios.post(api, obj, header);
      if (data.status == "error") reject(data.message);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const PUTAPI = (api, obj = {}, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = token
        ? {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        : {};
      const { data } = await axios.put(api, obj, header);
      if (data.status == "error") reject(data.message);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

export const DELETEAPI = (api, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = token
        ? {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        : {};

      const { data } = await axios.delete(api, header);
      if (data.status == "error") reject(data.message);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};
