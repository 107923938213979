import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import splashscreenlogo from "../Image/splashscreenlogo.svg";
import { useCookies } from "react-cookie";
const SplashScreen = () => {
  const [cookie] = useCookies(["user"]);
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (cookie?.user) return window.location.replace("/Home");
      window.location.replace("/Signin");
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <div className="bg-white">
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-3 col-md-4 col-6 my-3">
            <div className="row align-items-center">
              <img
                src={splashscreenlogo}
                className="img-fluid"
                alt="Splash Screen Logo"
              />
              <Link to={"https://mechodal.com/"} className="text-center">
                Mechodal Technology
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
