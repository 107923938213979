import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import posttwo from "../Image/post-two.svg";
import postthree from "../Image/PostThree.svg";
import logo from "../Image/logo.svg";
import doc from "../Image/doc.svg";
import { DELETEAPI, GETAPI, POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import NotFound from "../Image/notfound.svg";
import psss from "../Image/ppt-post.svg";
import pdficon from "../Image/pdf-icon.svg";
import docIcon from "../Image/doc-flie.svg";
import ppticon from "../Image/ppt-icon.svg";
import { toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";

function PostAbout() {
  const [PostDetail, setPostDetail] = useState(null);
  const [LikePost, setLikePost] = useState([]);
  const [AllLikePost, setAllLikePost] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isLike, setisLike] = useState(false);
  const [cookie] = useCookies(["user", "role"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleSearch = (e) => {
    let key = e.target.value;
    const MainData = AllLikePost?.filter(
      (el) =>
        el?.title?.toLowerCase()?.includes(key.toLowerCase()) ||
        el?.detail?.toLowerCase()?.includes(key.toLowerCase()) ||
        el?.author?.name?.toLowerCase()?.includes(key.toLowerCase())
    );
    setLikePost((prev) => MainData);
  };
  const handleLikes = async () => {
    try {
      if (isLike) {
        setPostDetail((prev) => ({ ...prev, likes: prev.likes - 1 }));
        setisLike(false);
        await DELETEAPI(`${Api.Like}/${id}`, cookie.user);
      } else {
        setPostDetail((prev) => ({ ...prev, likes: prev.likes + 1 }));
        setisLike(true);
        await POSTAPI(Api.Like, { id }, cookie.user);
      }
    } catch (er) {
      console.log(er);
    }
  };

  const handleDeletePost = () => {
    toast
      .promise(DELETEAPI(`${Api.Post}/${id}`, cookie.user), {
        pending: "Wait Few Moment !",
        success: "Post Delete Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        navigate("/Post");
      })
      .catch(() => {});
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        setisLike(false);
        const data = await GETAPI(`${Api.Post}/${id}`, cookie.user);
        setPostDetail((prev) => data.data);
        setisLike((prev) => data.data.isLike);

        setLikePost((prev) => data.likeData);
        setAllLikePost((prev) => data.likeData);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [id]);
  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        {!isLoad ? (
          <div
            className="d-flex justify-content-center  align-items-center  mt-5 "
            style={{ height: "70vh" }}
          >
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 mb-3">
              <div className="back-post-section">
                <Link to={"/Post"} type="submit">
                  {" "}
                  <i class="fa-solid fa-arrow-left"></i>{" "}
                </Link>
                <h4>Post Section</h4>
              </div>

              <div className="post-card-two">
                <div className="post-card-two-img">
                  {PostDetail?.type == "image" && (
                    <img
                      src={PostDetail?.content}
                      onError={(e) => (e.currentTarget.src = doc)}
                      className="img-fluid "
                      alt="s"
                    />
                  )}
                  {PostDetail?.type == "video" && (
                    <video className="img-fluid " controls>
                      <source src={PostDetail?.content} />
                    </video>
                  )}
                  {PostDetail?.type == "pdf" && (
                    <img src={posttwo} className="img-fluid" alt="" />
                  )}
                  {(PostDetail?.type == "pptx" ||
                    PostDetail?.type == "ppt") && (
                    <img src={psss} className="" alt="" />
                  )}
                  {PostDetail?.type == "doc" && (
                    <img src={postthree} className="img-fluid" alt="" />
                  )}
                  <div
                    className={`heart-rating ${isLike && "active-like"}`}
                    onClick={handleLikes}
                  >
                    <h3 className="d-flex justify-content-center  align-items-center ">
                      <i class="fa-solid fa-heart"></i>
                      <span>{PostDetail?.likes}</span>
                    </h3>
                  </div>
                </div>
                <div className="set-grafic-icon">
                  <h5>{PostDetail?.title}</h5>
                  <p>{PostDetail?.detail}</p>
                  {!(
                    PostDetail?.type == "image" || PostDetail?.type == "video"
                  ) && (
                    <div className="doc-view ppt-view">
                      <div className="doc-file-img-text">
                        {PostDetail?.type == "pdf" && (
                          <img src={pdficon} className="img-fluid" />
                        )}
                        {PostDetail?.type == "doc" && (
                          <img src={docIcon} className="img-fluid" />
                        )}
                        {(PostDetail?.type == "ppt" ||
                          PostDetail?.type == "pptx") && (
                          <img src={ppticon} className="img-fluid" />
                        )}
                        <h4>{PostDetail?.content?.split("/").slice(-1)}</h4>
                      </div>
                      <div class="post-section my-0">
                        <Link
                          data-bs-toggle="modal"
                          id="modalOpen"
                          data-bs-target="#addpoint"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="graphic-icon">
                    <div className="d-flex align-items-center">
                      <img
                        src={PostDetail?.author?.image}
                        style={{
                          width: "36px",
                          height: "35px",
                          objectFit: "fill",
                        }}
                        onError={(e) => (e.currentTarget.src = logo)}
                        className="img-fluid rounded-5   "
                        alt="logo"
                      />
                      <h6>{PostDetail?.author?.name}</h6>
                    </div>
                    {cookie.role != "employee" && (
                      <div class="firesafety-delete">
                        <Link onClick={handleDeletePost}>
                          <i class="fa-solid fa-trash"></i>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-12">
              <div className="postabout-sec-bg">
                <div className="pro-add-new px-0">
                  <p>You Also Like This</p>
                  <div class="form-group has-search header-bottom-search post-page-search-bg">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="all-project-table">
                  {!LikePost.length ? (
                    <div className="d-flex justify-content-center  align-items-center flex-column   h-100">
                      <img src={NotFound} alt="" className="w-75   h-75" />
                      <h4>Post Not Available !</h4>
                    </div>
                  ) : (
                    LikePost.map((item) => {
                      return (
                        <div
                          className="post-details-in mb-3 me-2"
                          onClick={() => navigate(`/PostAbout/${item?._id}`)}
                        >
                          <div className="also-like-this">
                            <div className="d-flex">
                              <div className="p-img-set">
                                {item?.type == "image" && (
                                  <img
                                    src={item?.content}
                                    onError={(e) => (e.currentTarget.src = doc)}
                                    className="img-fluid "
                                    alt="s"
                                  />
                                )}
                                {item?.type == "video" && (
                                  <video className="img-fluid ">
                                    <source src={item?.content} />
                                  </video>
                                )}
                                {item?.type == "pdf" && (
                                  <img
                                    src={posttwo}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                {(item?.type == "pptx" ||
                                  item?.type == "ppt") && (
                                  <img src={psss} className="" alt="" />
                                )}
                                {item?.type == "doc" && (
                                  <img
                                    src={postthree}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="set-grafic-icon p-0 me-3">
                                <div className="d-set-heart-icon">
                                  <div className="set-grafic-icon-to">
                                    <h5 className="text-cut">{item?.title}</h5>
                                    <p className="text-cut">{item?.detail}</p>
                                    <hr />
                                  </div>
                                </div>
                                <div className="graphic-icon">
                                  <img
                                    src={item?.author?.image}
                                    className="img-fluid  rounded-5  "
                                    style={{
                                      width: "36px",
                                      height: "35px",
                                      objectFit: "fill",
                                    }}
                                    onError={(e) =>
                                      (e.currentTarget.src = logo)
                                    }
                                    alt="logo"
                                  />
                                  <h6>{item?.author?.name}</h6>
                                </div>
                              </div>
                            </div>
                            <div className={`heart-rating-to `}>
                              <h3>
                                <i class="fa-solid fa-heart"></i>
                                {item?.likes}
                              </h3>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div
                class="modal fade "
                id="addpoint"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog  modal-xl  ">
                  <div
                    class="modal-content spl-modal-body"
                    style={{ height: "100vh" }}
                  >
                    <div className="close-modal-btn">
                      <Link
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </Link>
                    </div>
                    <div class="modal-body h-100">
                      {PostDetail?.type == "pdf" && (
                        <embed
                          class="pdf"
                          src={PostDetail?.content}
                          width="100%"
                          height="100%"
                        />
                      )}
                      {(PostDetail?.type == "pptx" ||
                        PostDetail?.type == "ppt") && (
                        <embed
                          src={PostDetail?.content}
                          type="application/vnd.openxmlformats-officedocument.presentationml.presentation"
                          width="800"
                          height="600"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PostAbout;
