import React from "react";
import { formatDate } from "../utils/ExtraFunction";

const Certificate = ({ title, user, date }) => {
  return (
    <div
      class="modal fade "
      id="certificate"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog  modal-xl  ">
        <div class="modal-content spl-modal-body">
          <div class="page" id="CertificatePDF">
            <div className="cerBody">
              <div className="border-pattern">
                <div className="content">
                  <div className="inner-content">
                    <h1>Certificate</h1>
                    <h2>of Excellence</h2>
                    <h3>This Certificate Is Proudly Presented To</h3>
                    <p>{user}</p>
                    <h3>Has Completed</h3>
                    <p>{title}</p>
                    <h3>On</h3>
                    <p>{formatDate(date)}</p>
                    <div className="badge"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
