import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ExportWorkSpace = ({ data, title = "Near" }) => {
  const [StartingDate, setStartingDate] = useState("");
  const [EndingDate, setEndingDate] = useState("");

  const handleExport = () => {
    if (!StartingDate || !EndingDate)
      return toast.error("Something Went Wrong !");
    const Starting = new Date(StartingDate);
    const Ending = new Date(EndingDate);

    // Filter the array based on the createdAt date falling within the specified range
    let filteredData = data.filter((entry) => {
      const createdAtDate = new Date(entry.createdAt);
      return createdAtDate >= Starting && createdAtDate <= Ending;
    });
    filteredData = filteredData?.map((item) => {
      delete item._id;
      delete item.type;
      delete item?.__v;
      delete item?.updatedAt;
      delete item?.isWatch;
      item["userId"] = item?.userId?._id;
      return item;
    });
    if (filteredData?.length) {
      const keys = Object.keys(filteredData[0]);
      const csvContent =
        "data:text/csv;charset=utf-8," +
        keys.join(",") +
        "\n" +
        filteredData
          .map((row) => keys.map((key) => row[key]).join(","))
          .join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${title}.csv`);
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error("Data Not Found !");
    }
    document.getElementById("closeModal").click();
  };
  return (
    <div
      class="modal fade "
      id="ExportWorkSpace"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog     ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              id="closeModal"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
            <div>
              <div className=" d-flex justify-content-center  align-items-center gap-3  ">
                <div class="form-group">
                  <label
                    for="exampleInputEmail1"
                    className="d-flex justify-content-start p-2"
                  >
                    <span>Starting Date</span>
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setStartingDate((prev) => e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label
                    for="exampleInputEmail1"
                    className="d-flex justify-content-start p-2"
                  >
                    <span>Ending Date</span>
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputEmail1"
                    onChange={(e) => setEndingDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 w-100 my-4">
                <button
                  type="submit"
                  className="w-100 submit-btn"
                  onClick={handleExport}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportWorkSpace;
