import React from "react";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import emi from "../Image/emergency-modal-icon.svg";
import { AiFillHome } from "react-icons/ai";
import { FaMobile } from "react-icons/fa6";
import { TbCalendarTime } from "react-icons/tb";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FiPieChart } from "react-icons/fi";
import { AiOutlineGlobal } from "react-icons/ai";
import { PiSirenLight } from "react-icons/pi";
import { TfiMapAlt } from "react-icons/tfi";
import { TbMapPinCheck } from "react-icons/tb";
import { PiNumberCircleFive } from "react-icons/pi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { PiPhoneCallLight } from "react-icons/pi";
import { PiPower } from "react-icons/pi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { useCookies } from "react-cookie";

const Sidebar = () => {
  const [cookie, , removeCookie] = useCookies([
    "user",
    "role",
    "companyImage",
    "companyName",
  ]);
  const location = useLocation();

  return (
    <div>
      {/* nav fixx */}
      <div className="sidebar">
        <div className="overflow-y">
          <div className="sidebar-link">
            <ul>
              <div className="page-line">
                <span>MENU</span>
              </div>
              <li className={location.pathname === "/Home" ? "active" : ""}>
                <NavLink to={"/Home"}>
                  <AiFillHome />
                  Home
                </NavLink>
              </li>
              <li className={location.pathname === "/Post" ? "active" : ""}>
                <NavLink to={"/Post"}>
                  <FaMobile />
                  ESH Post
                </NavLink>
              </li>
              <li className={location.pathname === "/Training" ? "active" : ""}>
                <NavLink to={"/Training"}>
                  <TbCalendarTime />
                  Training
                </NavLink>
              </li>
              <li
                className={location.pathname === "/PlantLayout" ? "active" : ""}
              >
                <NavLink to={"/PlantLayout"}>
                  <HiOutlineBuildingOffice2 />
                  Plant Layout
                </NavLink>
              </li>
              {cookie.role != "employee" && (
                <li
                  className={
                    location.pathname === "/EmployeeUpdate" ? "active" : ""
                  }
                >
                  <NavLink to={"/EmployeeUpdate"}>
                    <AiOutlineUserSwitch />
                    Employee Update
                  </NavLink>
                </li>
              )}
              <li
                className={
                  location.pathname === "/OrganizationChart" ? "active" : ""
                }
              >
                <NavLink to={"/OrganizationChart"}>
                  <FiPieChart />
                  Organization Chart
                </NavLink>
              </li>
              {cookie.role != "employee" && (
                <li
                  className={location.pathname === "/Community" ? "active" : ""}
                >
                  <NavLink to={"/Community"}>
                    <AiOutlineGlobal />
                    Community
                  </NavLink>
                </li>
              )}
              <li
                className={
                  location.pathname === "/EmergencyContact" ? "active" : ""
                }
              >
                <NavLink to={"/EmergencyContact"}>
                  <PiSirenLight />
                  Emergency Contact
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/NearMissMain" ? "active" : ""
                }
              >
                <NavLink to={"/NearMissMain"}>
                  <TfiMapAlt />
                  Near Miss
                </NavLink>
              </li>
              <li
                className={location.pathname === "/KaizenMain" ? "active" : ""}
              >
                <NavLink to={"/KaizenMain"}>
                  <TbMapPinCheck />
                  Kaizen
                </NavLink>
              </li>
              <li
                className={location.pathname === "/FivesSystem" ? "active" : ""}
              >
                <NavLink to={"/FivesSystem"}>
                  <PiNumberCircleFive />
                  5s System
                </NavLink>
              </li>
              <div className="page-line">
                <span>MORE</span>
              </div>
              <li
                className={
                  location.pathname === "/AboutCompany" ? "active" : ""
                }
              >
                <NavLink to={"/AboutCompany"}>
                  <HiOutlineShoppingBag />
                  Privacy policy
                </NavLink>
              </li>
              <li
                className={location.pathname === "/ContactUs" ? "active" : ""}
              >
                <NavLink to={"/ContactUs"}>
                  <PiPhoneCallLight />
                  Contact Us
                </NavLink>
              </li>
              <li className={location.pathname === "/Signin" ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    removeCookie("user");
                    removeCookie("role");
                    removeCookie("companyImage");
                    removeCookie("companyName");
                  }}
                  to={"/Signin"}
                >
                  <PiPower />
                  Log Out
                </NavLink>
              </li>
              {/* <li className={location.pathname === '/UploadImagePdf' ? 'active' : ''}><NavLink to={"/UploadImagePdf"}><i className="fa-solid fa-user"></i>Upload Image & PDF</NavLink></li> */}
              {/* <li className={location.pathname === '#' ? 'active' : ''}><NavLink to={"#"} data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fa-solid fa-user"></i>EMG Number</NavLink></li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* Emergency Contact  */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body modal-emergency">
              <div className="close-modal-btn">
                <Link type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fa-solid fa-xmark"></i>
                </Link>
              </div>
              <div className="eme-logo-text">
                <img src={emi} className="img-fluid" alt="S" />
                <h3>Emergency Contact</h3>
              </div>
              <div className="fire-safety-call">
                <p>Fire Safety </p>
                <h5>
                  <Link to={"tel:+919876543210"}>+91 9876543210</Link>
                </h5>
              </div>
              <div className="fire-safety-call">
                <p>Fire Safety </p>
                <h5>
                  <Link to={"tel:+919876543210"}>+91 9876543210</Link>
                </h5>
              </div>
              <div className="fire-safety-call">
                <p>Fire Safety </p>
                <h5>
                  <Link to={"tel:+919876543210"}>+91 9876543210</Link>
                </h5>
              </div>
              <div className="fire-safety-call">
                <p>Fire Safety </p>
                <h5>
                  <Link to={"tel:+919876543210"}>+91 9876543210</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
