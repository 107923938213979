import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Link } from "react-router-dom";
import pdficon from "../Image/pdf-icon.svg";
import NotFound from "../Image/notfound.svg";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";

const OrganizationChart = () => {
  const [ChartDetail, setChartDetail] = useState(null);
  const [cookie] = useCookies(["user", "companyName", "role"]);
  const [isLoad, setisLoad] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Chart}/${cookie.companyName}?type=orgination`,
          cookie.user
        );
        setChartDetail((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div className="asside">
      <div className="container-fluid">
        {<Sidebar />}
        {<Header />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="back-post-section mb-0">
            <h4 className="ms-0">Organization Chart</h4>
          </div>
          {cookie.role != "employee" && (
            <div className="modal-add-emergency">
              <Link to={"/OrganizationUpload"}>
                <i class="fa-solid fa-plus"></i>
                {ChartDetail ? "Update" : "Add New"} Organization Chart
              </Link>
            </div>
          )}
        </div>
        {!isLoad ? (
          <div className="d-flex justify-content-center  align-items-center  h-75 my-5">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : !ChartDetail ? (
          <div className="d-flex justify-content-center  align-items-center  flex-column  h-100">
            <img src={NotFound} className="w-25 h-25 img-fluid " alt="" />
            <h5>OrganizationChart Not Found !</h5>
          </div>
        ) : (
          <div className="lesson-text p-color">
            {/* <p>
              In this lesson, you use navigation controllers and segues to
              create the navigation flow of the FoodTracker app. At the end of
              the lesson, you’ll have a complete navigation scheme and
              interaction flow for the app.
            </p>
            <p>
              In this lesson, you use navigation controllers and segues to
              create the navigation flow of the FoodTracker app. At the end of
              the lesson, you’ll have a complete navigation scheme and
              interaction flow for the app.
            </p> */}
            <div className="doc-view ppt-view" style={{ height: "75vh" }}>
              <embed
                class="pdf"
                src={ChartDetail?.document}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationChart;
