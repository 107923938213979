import React from 'react'
import Sidebar from './Sidebar';
import Header from './Header';
import { Link } from "react-router-dom";
import pdficon from '../Image/pdf-icon.svg';

const AboutCompany = () => {
  return (
    <div className='asside'>
        <div className='container-fluid'>
            {<Sidebar/>}
            {<Header/>}
            <div className='back-post-section mb-0'>
                <h4 className='ms-0'>Privacy policy</h4>
            </div>
            <div className='lesson-text p-color'>
                <p>In this lesson, you use navigation controllers and segues to create the navigation flow of the FoodTracker app. At the end of the lesson, you’ll have a complete navigation scheme and interaction flow for the app.</p>
                <p>In this lesson, you use navigation controllers and segues to create the navigation flow of the FoodTracker app. At the end of the lesson, you’ll have a complete navigation scheme and interaction flow for the app.</p>
                <h6>Lorem Issues</h6>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makeLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to makeLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make </p>
                <div className='doc-view ppt-view'>
                    <div className='doc-file-img-text'>
                        <img src={pdficon} className='img-fluid' alt=''/>
                        <h4>Insure the safety of the children.<span>pdf</span></h4>
                    </div>
                    <div class="post-section my-0"><Link to={"#"}>View</Link></div>
                </div>             
            </div>
        </div>
    </div>
  )
}


export default AboutCompany
