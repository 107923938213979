import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import pdficon from "../Image/pdf-icon.svg";
import equityicon from "../Image/equityicon.svg";
import lockicon from "../Image/lock-icon.svg";
import scoreicon from "../Image/scoreicon.svg";
import successimg from "../Image/success-img.svg";
import { DELETEAPI, GETAPI, POSTAPI, PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import { MdPlayCircle } from "react-icons/md";
import { toast } from "react-toastify";
import Certificate from "../common/Certificate";
import useGetInfo from "../hook/useGetInfo";
import html2pdf from "html2pdf.js";

function checkIndexInArray(index, array) {
  if (index >= 0 && index < array.length) {
    return true;
  } else {
    return false;
  }
}

function TrainingAbove() {
  const { id } = useParams();
  const [AllTraining, setAllTraining] = useState([]);
  const [Training, setTraining] = useState([]);
  const [Quiz, setQuiz] = useState([]);
  const [like, setlike] = useState(false);
  const [MainVideo, setMainVideo] = useState(null);
  const [isLoad, setisLoad] = useState(false);
  const [cookies] = useCookies(["user", "companyImage", "companyName"]);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { User } = useGetInfo();
  const handleVideoEnd = async () => {
    const video = videoRef.current;
    if (video.currentTime === video.duration) {
      await PUTAPI(Api.Watch, { id }, cookies.user);
      if (!Quiz?.length && MainVideo?.questions?.length)
        return toast.error("Before The Next  Video Complete Quiz !");
      let index = AllTraining.findIndex((item) => item?._id == id);
      let prev = AllTraining;
      if (checkIndexInArray(index + 1, prev)) {
        if (prev[index + 1]?.isWatch == false) {
          let newData = prev.map((item, no) => {
            if (no == index + 1) return { ...item, isWatch: true };
            else return item;
          });
          setAllTraining((prev) => newData);
          setTraining((prev) => newData);
          POSTAPI(
            Api.AddCertificate,
            { id: AllTraining[index]._id },
            cookies.user
          );
          POSTAPI(
            Api.Watch,
            { postId: AllTraining[index + 1]?._id },
            cookies.user
          );
          toast("Now Watch Next Video !");
        }
        navigate(`/TrainingAbove/${AllTraining[index + 1]?._id}`);
      }
    }
  };

  const handleLike = async () => {
    try {
      setlike((prev) => !like);
      if (!like) {
        setMainVideo((prev) => ({
          ...prev,
          likes: prev.likes + 1,
        }));
        await PUTAPI(Api.TrainingLike, { id }, cookies.user);
      } else {
        setMainVideo((prev) => ({
          ...prev,
          likes: prev.likes - 1,
        }));
        await DELETEAPI(`${Api.TrainingLike}/${id}`, cookies.user);
      }
    } catch (er) {
      console.log(er);
    }
  };

  const handleDownloadCerticate = () => {
    const element = document.getElementById("CertificatePDF");
    html2pdf(element, {
      margin: 0.2,
      filename: "Certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        orientation: "p",
        unit: "in",
        format: "a3",
        putOnlyUsedFonts: true,
        // floatPrecision: 16,
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Training}?type=${cookies.companyName}`,
          cookies.user
        );
        let main = "Company";
        if (data.data.MainData.some((el) => el._id == id)) {
          setAllTraining((prev) => data.data.MainData);
          setTraining((prev) => data.data.MainData);
          main = "MainData";
        } else {
          setAllTraining((prev) => data.data.Company);
          setTraining((prev) => data.data.Company);
        }

        let index = data.data[main].findIndex((item) => item?._id == id);
        setMainVideo((prev) => data.data[main][index]);
        setlike((prev) => data.data[main][index]?.isLike);
        const QuizData = await GETAPI(`${Api.Quiz}/${id}`, cookies.user);
        setQuiz((prev) => QuizData?.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [id]);
  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        {!isLoad ? (
          <div
            className="d-flex justify-content-center  align-items-center "
            style={{ height: "70vh" }}
          >
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 mb-3">
              <div className="back-post-section">
                <Link to={"/Training"} type="submit">
                  {" "}
                  <i class="fa-solid fa-arrow-left"></i>{" "}
                </Link>
                <h4>
                  Episode{" "}
                  {AllTraining?.findIndex((item) => item?._id == id) + 1} –
                  {MainVideo?.title}
                </h4>
              </div>

              <div className="post-card-two bg-transparent shadow-none">
                <div className="training-above-video">
                  <video controls ref={videoRef} onEnded={handleVideoEnd}>
                    <source src={MainVideo?.video} />
                  </video>
                  <div className={`heart-rating ${like && "active-like"}  `}>
                    <h3
                      className="d-flex justify-content-center  align-items-center flex-column "
                      onClick={handleLike}
                    >
                      <i class="fa-solid fa-heart"></i>
                      <span>{MainVideo?.likes}</span>
                    </h3>
                  </div>
                </div>
                <div className="lesson-text">
                  <h5>{MainVideo?.title}</h5>
                  <p>{MainVideo?.about}</p>

                  <div className="doc-view ppt-view">
                    <div className="doc-file-img-text">
                      <img src={pdficon} className="img-fluid" alt="" />
                      <h4>{MainVideo?.documentFile?.split("/").slice(-1)}</h4>
                    </div>
                    <div class="post-section my-0">
                      <Link
                        data-bs-toggle="modal"
                        id="modalOpen"
                        data-bs-target="#addpoint"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                  {!Quiz?.length ? (
                    MainVideo?.questions?.length ? (
                      <div className="session-quize-get">
                        <h2>
                          {MainVideo?.type} Episode{" "}
                          {AllTraining?.findIndex((item) => item?._id == id) +
                            1}{" "}
                          quiz{" "}
                        </h2>
                        <div className="equity-driven">
                          <img src={equityicon} className="img-fluid" alt="" />
                          <p className="mb-0">
                            Equity-driven assessment and practice upgrades
                          </p>
                        </div>
                        <Link to={`/Quiz/${id}`} className="btn get-quote-btn">
                          Get a Quiz <i class="fa-solid fa-chevron-right"></i>
                        </Link>
                      </div>
                    ) : null
                  ) : (
                    <>
                      <div className="session-quize-certificate">
                        <h2>
                          Congratulation You Got <span>Certificate</span>
                        </h2>
                        <div className="certificate-view-download">
                          <div className="certificate-mb">
                            <i class="fa-regular fa-file"></i>
                            <div className="quiz-certificate">
                              <h5 className="mb-1">Quiz Certificate</h5>
                              {/* <p className="mb-0">1.25 MB</p> */}
                            </div>
                          </div>
                          <div className="down-view-btn">
                            <div className="view-btn">
                              <Link
                                data-bs-toggle="modal"
                                id="modalOpen"
                                data-bs-target="#certificate"
                              >
                                View
                              </Link>
                            </div>
                            <div className="down-btn">
                              <Link onClick={handleDownloadCerticate}>
                                <i class="fa-solid fa-download"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="session-quize-completed">
                        <h2>
                          {MainVideo?.type} Episode{" "}
                          {AllTraining?.findIndex((item) => item?._id == id)}{" "}
                          Quiz
                          <span>Completed</span>
                        </h2>
                        <div className="score-success">
                          <div>
                            <div className="scroe-type">
                              <img
                                src={scoreicon}
                                className="img-fluid"
                                alt=""
                              />
                              <p className="mb-0">{Quiz?.[0]?.score} score</p>
                            </div>
                            <Link
                              to={`/QuizResult/${id}`}
                              className="btn get-quote-btn"
                            >
                              See Quize History{" "}
                              <i class="fa-solid fa-chevron-right"></i>
                            </Link>
                          </div>
                          <div className="success-img">
                            <img
                              src={successimg}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-12">
              <div className="postabout-sec-bg">
                <div className="pro-add-new px-0">
                  <p>Next Training</p>
                  <div class="form-group has-search header-bottom-search post-page-search-bg">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>

                <div className="all-project-table">
                  {Training.map((item, index) => {
                    return (
                      <div className="post-details-in mb-3 me-2">
                        <div className="also-like-this">
                          <div>
                            <div className="next-video-img-set">
                              <video className="img-fluid">
                                <source src={item?.video} />
                              </video>

                              <Link
                                to={
                                  item?.isWatch
                                    ? `/TrainingAbove/${item?._id}`
                                    : ""
                                }
                                className={`next-video-lock  ${
                                  item?.isWatch && "play-video"
                                }`}
                              >
                                {item?.isWatch ? (
                                  <>
                                    <MdPlayCircle
                                      size={55}
                                      color="rgba(172,172,172,0.8)"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={lockicon}
                                      className="img-fluid "
                                      alt=""
                                    />
                                    <h4>Next Video</h4>
                                    <p>
                                      Complete Previous video to continue
                                      learning
                                    </p>
                                  </>
                                )}
                              </Link>
                            </div>
                          </div>
                          <div>
                            <div className="set-grafic-icon p-0 ">
                              <div className="d-set-heart-icon">
                                <div className="set-grafic-icon-to me-0">
                                  <h5>
                                    {item?.title} || Episode {index + 1}
                                  </h5>
                                  <p className="text-cut">{item?.about}</p>
                                  <hr />
                                </div>
                              </div>
                              <div className="graphic-icon">
                                <h6>{item?.type} you training session</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              class="modal fade "
              id="addpoint"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog  modal-xl  ">
                <div
                  class="modal-content spl-modal-body"
                  style={{ height: "100vh" }}
                >
                  <div className="close-modal-btn">
                    <Link
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </Link>
                  </div>
                  <div class="modal-body h-100">
                    <embed
                      class="pdf"
                      src={MainVideo?.documentFile}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Certificate
              title={MainVideo?.title}
              user={User?.name}
              date={Quiz?.[0]?.createdAt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainingAbove;
