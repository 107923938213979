import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Homedemo from "./components/Homedemo";
import Promotions from "./components/Promotions";
import VehicleTypes from "./components/VehicleTypes";
import DriverDocuments from "./components/DriverDocuments";
import Bookings from "./components/Bookings";
import HelpSupport from "./components/HelpSupport";
import Scrolltotop from "./Scrolltotop";
import SplashScreen from "./components/SplashScreen";
import Signin from "./components/Signin";
import EmailVerification from "./components/EmailVerification";
import Successfullysentmail from "./components/Successfullysentmail";
import ChangePassword from "./components/ChangePassword";
import Post from "./components/Post";
import EmergencyContact from "./components/EmergencyContact";
import PostAbout from "./components/PostAbout";
import VideoPostAbout from "./components/VideoPostAbout";
import DocPostAbout from "./components/DocPostAbout";
import PostPDF from "./components/PostPDF";
import PostPPT from "./components/PostPPT";
import Notification from "./components/Notification";
import Training from "./components/Training";
import TrainingAbove from "./components/TrainingAbove";
import Quiz from "./components/Quiz";
import QuizTwo from "./components/QuizTwo";
import QuizThree from "./components/QuizThree";
import QuizResult from "./components/QuizResult";
import EmployeeUpdate from "./components/EmployeeUpdate";
import OrganizationChart from "./components/OrganizationChart";
import FivesSystem from "./components/FivesSystem";
import AboutCompany from "./components/AboutCompany";
import NearMiss from "./components/NearMiss";
import Kaizen from "./components/Kaizen";
import ContactUs from "./components/ContactUs";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import PlantLayout from "./components/PlantLayout";
import UploadImagePdf from "./components/UploadImagePdf";
import UploadPost from "./components/UploadPost";
import Community from "./components/Community";
import UploadNewTrainingSession from "./components/UploadNewTrainingSession";
import EmployeeUpdateMain from "./components/EmployeeUpdateMain";
import OrganizationUpload from "./components/OrganizationUpload";
import NearMissMain from "./components/NearMissMain";
import KaizenMain from "./components/KaizenMain";
import Visitorpeople from "./components/Visitorpeople";
import CompanyProfile from "./components/CompanyProfile";
import CompanyProfileEdit from "./components/CompanyProfileEdit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FivesSystemUpload from "./components/FivesSystemUpload";
function App() {
  return (
    <div className="App">
      <Router>
        <Scrolltotop />
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/EmailVerification" element={<EmailVerification />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Homedemo" element={<Homedemo />} />
          <Route path="/Post" element={<Post />} />
          <Route path="/EmergencyContact" element={<EmergencyContact />} />
          <Route path="/PostAbout/:id" element={<PostAbout />} />
          <Route path="/VideoPostAbout" element={<VideoPostAbout />} />
          <Route path="/DocPostAbout" element={<DocPostAbout />} />
          <Route path="/PostPDF" element={<PostPDF />} />
          <Route path="/PostPPT" element={<PostPPT />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Training" element={<Training />} />
          <Route path="/TrainingAbove/:id" element={<TrainingAbove />} />
          <Route path="/Quiz/:id" element={<Quiz />} />
          <Route path="/QuizTwo" element={<QuizTwo />} />
          <Route path="/QuizThree" element={<QuizThree />} />
          <Route path="/QuizResult/:id" element={<QuizResult />} />
          <Route path="/EmployeeUpdate" element={<EmployeeUpdate />} />
          <Route path="/Promotions" element={<Promotions />} />
          <Route path="/VehicleTypes" element={<VehicleTypes />} />
          <Route path="/DriverDocuments" element={<DriverDocuments />} />
          <Route path="/Bookings" element={<Bookings />} />
          <Route path="/HelpSupport" element={<HelpSupport />} />
          <Route
            path="/Successfullysentmail"
            element={<Successfullysentmail />}
          />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/OrganizationChart" element={<OrganizationChart />} />
          <Route path="/FivesSystem" element={<FivesSystem />} />
          <Route path="/AboutCompany" element={<AboutCompany />} />
          <Route path="/NearMiss/:id" element={<NearMiss />} />
          <Route path="/Kaizen/:id" element={<Kaizen />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/PlantLayout" element={<PlantLayout />} />
          <Route path="/UploadImagePdf/:id" element={<UploadImagePdf />} />
          <Route path="/EditProfile" element={<EditProfile />} />
          <Route path="/UploadPost" element={<UploadPost />} />
          <Route path="/Community" element={<Community />} />
          <Route
            path="/UploadNewTrainingSession"
            element={<UploadNewTrainingSession />}
          />
          <Route
            path="/EmployeeUpdateMain/:id"
            element={<EmployeeUpdateMain />}
          />
          <Route path="/OrganizationUpload" element={<OrganizationUpload />} />
          <Route path="/FivesSystemUpload" element={<FivesSystemUpload />} />
          <Route path="/NearMissMain" element={<NearMissMain />} />
          <Route path="/KaizenMain" element={<KaizenMain />} />
          <Route path="/Visitorpeople" element={<Visitorpeople />} />
          <Route path="/CompanyProfile" element={<CompanyProfile />} />
          <Route path="/CompanyProfileEdit" element={<CompanyProfileEdit />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
