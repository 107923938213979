import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import java from "../Image/logo.svg";
import useComapany from "../hook/useComapany";
import { MutatingDots } from "react-loader-spinner";

function CompanyProfile() {
  const { isLoad, Company } = useComapany();

  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/Home"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Company Profile</h4>
            </div>
          </div>
        </div>
      </div>
      {/**Profile Section Start */}
      <div className="container ">
        <div className="row justify-content-center">
          {!isLoad ? (
            <div className="col-12">
              <div className="d-flex justify-content-center  align-items-center ">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#0841D4"
                  secondaryColor="#0841D4"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                />
              </div>
            </div>
          ) : (
            <div className="col-lg-7 col-md-12 profile-section-back-design">
              <div className="jus-end">
                <Link to={"/CompanyProfileEdit"}>
                  <div className="profile-setting ">
                    <i class="fa-solid fa-gear"></i>
                  </div>
                </Link>
              </div>
              <div className="pro-padding-f">
                <div className="profile-upload-img w-75 h-75">
                  <img
                    src={Company?.image}
                    onError={(e) => (e.currentTarget.src = java)}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="profiletext">
                  <h3>{Company?.name}</h3>
                </div>
                <div className="profile-info">
                  <h3>Information</h3>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">
                    About Company:
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    value={Company?.about}
                    disabled
                  ></textarea>
                </div>
                <div className="d-flex justify-content-center  align-items-center ">
                  <video
                    src={Company?.video}
                    controls
                    className="w-75 h-75"
                  ></video>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/**Profile Section End */}
    </div>
  );
}

export default CompanyProfile;
