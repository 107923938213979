import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import trainingvc from "../Image/saf4youtraning.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PiaChart from "./PiaChart";

import NotFound from "../Image/notfound.svg";
import graphicicon from "../Image/graphic-icon.svg";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import TrainingChart from "../common/TrainingChart";

function Training() {
  const [AllTraining, setAllTraining] = useState([]);
  const [Training, setTraining] = useState([]);
  const [AllCompany, setAllCompany] = useState([]);
  const [Compoany, setCompoany] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [cookie] = useCookies(["user", "role", "companyName", "companyImage"]);

  const tainingslider = {
    items: 1,
    loop: false,
    autoplay: true,
    autoplayTimeout: 100000,
    animateOut: "slideoutup",
    nav: false,
    dots: false,
    margin: 15,
    responsive: {
      1350: {
        items: 5,
      },
      1150: {
        items: 4,
      },
      940: {
        items: 3,
      },
      500: {
        items: 2,
      },
      370: {
        items: 1,
        innerwidth: "100%",
        outerwidth: "100%",
      },
    },
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Training}?type=${cookie.companyName}`,
          cookie.user
        );

        setAllTraining((prev) => data.data.MainData);
        setTraining((prev) => data.data.MainData);
        setAllCompany((prev) => data.data.Company);
        setCompoany((prev) => data.data.Company);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div className="asside ">
      <Header />
      <Sidebar />
      {!isLoad ? (
        <div
          className="d-flex justify-content-center  align-items-center  "
          style={{ height: "70vh" }}
        >
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0841D4"
            secondaryColor="#0841D4"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
          />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="post-section">
              <h3>Training Sessions</h3>

              {/* <Link to={"/TrainingAbove"} className="ms-2">
                See all<i class="fa-solid fa-arrow-right"></i>
              </Link> */}
            </div>
            <div className="row">
              {!Training?.length ? (
                <div className="col-12  ">
                  <div className="d-flex justify-content-center  align-items-center  flex-column my-4">
                    <img src={NotFound} style={{ width: "15%" }} alt="" />
                    <h5>Training Not Found !</h5>
                  </div>
                </div>
              ) : (
                <OwlCarousel className="owl-theme" {...tainingslider}>
                  {Training.map((item) => {
                    return (
                      <div className="training-session-txt post-card hover-video-btn">
                        <div className="trainingcd-img ">
                          <video>
                            <source src={item?.video} />
                          </video>

                          <div
                            className={`taining-icon-play ${
                              !item?.isWatch && "lock"
                            }`}
                          >
                            <Link
                              to={`${
                                !item.isWatch
                                  ? ""
                                  : `/TrainingAbove/${item?._id}`
                              }`}
                            >
                              {!item?.isWatch ? (
                                <i className={`fa-solid fa-lock`}></i>
                              ) : (
                                <i className={`fa-solid fa-play`}></i>
                              )}
                            </Link>
                            {!item?.isWatch && (
                              <p>
                                Complete Previous video to continue learning
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="insure-txt">
                          <h5 className="me-0 text-cut2">{item?.title}</h5>
                          <hr />
                          <div className="graphic-icon">
                            <img
                              src={item?.userId.image}
                              className="img-fluid  rounded-5 "
                              style={{ width: "7%" }}
                              alt="logo"
                            />
                            <h6>Safe4you</h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                <div className="post-section mt-0">
                  <h3>Training Sessions</h3>
                  {/* <Link to={"/TrainingAbove"}>
                    See all<i class="fa-solid fa-arrow-right"></i>
                  </Link> */}
                </div>
                <div className="row">
                  {cookie.role != "employee" && (
                    <div className="col-lg-4 col-md-6 col-6 mb-3">
                      <Link to={"/UploadNewTrainingSession"}>
                        <div className="upload-file-post upload-post-two">
                          <i class="fa-solid fa-video"></i>
                          <h3>Upload New Training Session</h3>
                        </div>
                      </Link>
                    </div>
                  )}
                  {Compoany.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-6 mb-3">
                        <div className="post-card">
                          <div className="img-card">
                            <div className="postion-btn">
                              <div className="in-img post-section-img hover-video-btn">
                                <video src={item?.video}></video>
                                <div
                                  className={`taining-icon-play ${
                                    !item?.isWatch && "lock"
                                  }`}
                                >
                                  <Link
                                    to={`${
                                      !item.isWatch
                                        ? ""
                                        : `/TrainingAbove/${item?._id}`
                                    }`}
                                  >
                                    {!item?.isWatch ? (
                                      <i className={`fa-solid fa-lock`}></i>
                                    ) : (
                                      <i className={`fa-solid fa-play`}></i>
                                    )}
                                  </Link>
                                  {!item?.isWatch && (
                                    <p>
                                      Complete Previous video to continue
                                      learning
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="insure-txt">
                              <h5 className="me-0 text-cut2">{item?.title}</h5>
                              <hr />
                              <div className="graphic-icon">
                                <img
                                  src={item.userId.image}
                                  className="img-fluid rounded-5  "
                                  alt="logo"
                                  style={{ width: "9%" }}
                                />
                                <h6>{item.userId.name}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xl-4 col-lg-7 col-md-10 col-12">
                <TrainingChart />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Training;

// <div className="col-lg-4 col-md-6 col-12 mb-3">
//   <div className="training-session-txt post-card hover-video-btn">
//     <div className="video-img-title">
//       <img src={trainingvc} className="img-fluid" alt="train" />
//       <div className="taining-icon-play">
//         <Link to={'#'}><i className={`fa-solid fa-play`}></i></Link>
//       </div>
//       <div className="v-title-ab">
//         <p>Complete Previous video to continue learning</p>
//       </div>
//       <div className='insure-txt'>
//         <h5>Insure the safety of the children </h5>
//         <hr />
//         <div className='graphic-icon'>
//           <img src={graphicicon} className='img-fluid' alt='logo' />
//           <h6>Graphiglow design</h6>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
