import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import java from "../Image/Java.png";
import uploadvideo from "../Image/upload-video-btn.svg";
import pdficon from "../Image/pdf-icon.svg";
import { MdCloudDone, MdFileUpload } from "react-icons/md";
import { FaRegPlusSquare } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";

const UploadNewTrainingSession = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [image, setImage] = useState("");
  const [Answer, setAnswer] = useState(1);
  const [cookie] = useCookies(["user", "companyImage", "companyName"]);
  const navigate = useNavigate();
  const [Document, setDocument] = useState(null);
  const [asn, setasn] = useState(false);
  const handleUploadTraining = (data) => {
    if (!image || !Document)
      return toast.error("Video or Document Must Be Required !");
    const options = [];
    const questions = [];
    // Collect all options
    if (asn) {
      for (let i = 0; i < Answer; i++) {
        options.push(
          data[`op1_${i}`],
          data[`op2_${i}`],
          data[`op3_${i}`],
          data[`op4_${i}`]
        );
      }

      // Check if any answer contains any option
      for (let i = 0; i < Answer; i++) {
        if (!options.includes(data[`asn_${i}`])) {
          return toast.error(
            `Answer of question ${i + 1} does not contain any of the options.`
          );
        }
      }

      for (let i = 0; i < Answer; i++) {
        const question = {
          question: data[`question${i}`],
          ans1: data[`op1_${i}`],
          ans2: data[`op2_${i}`],
          ans3: data[`op3_${i}`],
          ans4: data[`op4_${i}`],
          correct_ans: data[`asn_${i}`],
        };

        // Check if correct answer is one of the options

        if (
          new Set([question.ans1, question.ans2, question.ans3, question.ans4])
            .size !== 4
        ) {
          return toast.error(
            `Options for question ${i + 1} contain duplicates.`
          );
        }

        questions.push(question);
      }
    }

    const newForm = new FormData();
    newForm.append("video", image);
    newForm.append("title", data?.title);
    newForm.append("about", data?.about);
    newForm.append("documentFile", Document);
    newForm.append("type", cookie.companyName);
    newForm.append("questions", JSON.stringify(questions));

    toast
      .promise(POSTAPI(Api.Training, newForm, cookie.user), {
        pending: "Wait Few Moment !",
        success: "Training Add Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        navigate("/Training");
      })
      .catch(() => {});
    reset();
  };
  return (
    <div>
      <div className="asside-two">
        <div className="container-fluid">
          <Header />
          <div className="back-post-section">
            <Link to={"/Training"} type="submit">
              {" "}
              <i class="fa-solid fa-arrow-left"></i>{" "}
            </Link>
            <h4>Upload New Training Session</h4>
          </div>
          <div className="row justify-content-center ">
            <form
              className="col-lg-7 col-md-12"
              onSubmit={handleSubmit(handleUploadTraining)}
            >
              <div className="profile-upload-img-one">
                <input
                  type="file"
                  accept="video/*"
                  id="profile"
                  hidden
                  onChange={(e) => {
                    if (e.target.files.length) {
                      setImage((prev) => e.target.files[0]);
                    }
                  }}
                />
                {image ? (
                  <label htmlFor="profile">
                    <MdCloudDone size={66} color="#23aa2d" />
                  </label>
                ) : (
                  <div>
                    <label htmlFor="profile">
                      <img src={uploadvideo} alt=""></img>
                    </label>
                  </div>
                )}
                <h4>Upload Video Here</h4>
              </div>
              <div class="col-12 mb-3 ">
                <label
                  for="exampleFormControlInput1"
                  class="form-label frm-label"
                >
                  Video title
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  {...register("title", { required: true })}
                />
                {errors?.title && (
                  <p className="p-2 " style={{ color: "red" }}>
                    Please Enter Title !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label frm-label mt-0"
                >
                  About Post
                </label>
                <textarea
                  class="form-control "
                  id="exampleFormControlTextarea1"
                  rows="5"
                  {...register("about", { required: true })}
                ></textarea>
                {errors?.about && (
                  <p className="p-2 " style={{ color: "red" }}>
                    Please Enter About !
                  </p>
                )}
              </div>
              <div className="doc-view ppt-view">
                <div className="doc-file-img-text">
                  <img src={pdficon} className="img-fluid" alt="fd" />
                  <h4>
                    <span>Upload Document </span>
                  </h4>
                </div>
                <label htmlFor="pdf" className="uplaod-pdf p-0 bg-transparent">
                  <input
                    type="file"
                    name=""
                    id="pdf"
                    hidden
                    onChange={(e) => setDocument((prev) => e.target.files[0])}
                  />
                  <div className="btn btn-upload-docs d-flex align-items-center  justify-content-center   gap-2">
                    {!Document ? (
                      <MdFileUpload />
                    ) : (
                      <MdCloudDone size={25} color="#23aa2d" />
                    )}
                    <span>Upload Document</span>
                  </div>
                </label>
              </div>
              <div>
                <div className="add-quize-check">
                  <h3>Add Quiz</h3>
                  <div class="form-check add-btn-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      checked={asn}
                      onChange={() => setasn(!asn)}
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Add
                    </label>
                  </div>
                </div>
                {asn && (
                  <>
                    {[...Array(Answer).keys()].map((item) => {
                      return (
                        <div className="write-question-option mb-3">
                          <div class="col-12 mb-3">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label frm-label mt-0"
                            >
                              {item + 1} Write Question Here
                            </label>
                            <textarea
                              class="form-control "
                              id="exampleFormControlTextarea1"
                              rows="2"
                              {...register(`question${item}`, {
                                required: asn,
                              })}
                            ></textarea>
                            {errors[`question${item}`] && (
                              <p className="p-2" style={{ color: "red" }}>
                                Please Enter Question !
                              </p>
                            )}
                          </div>
                          <div class="col-12 mb-3 ">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label frm-label mt-0"
                            >
                              Write Option
                            </label>
                            <input
                              type="text"
                              class="form-control mb-2"
                              id="inputAddress"
                              placeholder="Type Option 1"
                              {...register(`op1_${item}`, {
                                required: asn,
                              })}
                            />
                            {errors[`op1_${item}`] && (
                              <p className="p-2" style={{ color: "red" }}>
                                Please Enter Option 1 !
                              </p>
                            )}
                            <input
                              type="text"
                              class="form-control mb-2"
                              id="inputAddress"
                              placeholder="Type Option 2"
                              {...register(`op2_${item}`, {
                                required: asn,
                              })}
                            />
                            {errors[`op2_${item}`] && (
                              <p className="p-2" style={{ color: "red" }}>
                                Please Enter Option 2 !
                              </p>
                            )}
                            <input
                              type="text"
                              class="form-control mb-2"
                              id="inputAddress"
                              placeholder="Type Option 3"
                              {...register(`op3_${item}`, {
                                required: asn,
                              })}
                            />
                            {errors[`op3_${item}`] && (
                              <p className="p-2" style={{ color: "red" }}>
                                Please Enter Option 2 !
                              </p>
                            )}
                            <input
                              type="text"
                              class="form-control mb-2"
                              id="inputAddress"
                              placeholder="Type Option 4"
                              {...register(`op4_${item}`, {
                                required: asn,
                              })}
                            />
                            {errors[`op2_${item}`] && (
                              <p className="p-2" style={{ color: "red" }}>
                                Please Enter Option 2 !
                              </p>
                            )}
                          </div>
                          <div class="col-12">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label frm-label mt-0"
                            >
                              Write Right Answer{" "}
                            </label>
                            <input
                              type="text"
                              class="form-control right-answer"
                              id="inputAddress"
                              placeholder="Right Answer"
                              {...register(`asn_${item}`, {
                                required: asn,
                              })}
                            />
                          </div>
                        </div>
                      );
                    })}

                    <div class="col-12 mb-3 ">
                      <button
                        type="button"
                        onClick={() => setAnswer(Answer + 1)}
                        class="add-more-q"
                      >
                        <FaRegPlusSquare /> Add More Question
                      </button>
                    </div>
                  </>
                )}
              </div>
              {/* <div class="col-12 mb-3 ">
                                <Link to={'#'} type="submit" class="add-more-q"><FaRegPlusSquare /> Add More Question</Link>
                            </div> */}
              <div class="col-12 mb-3 w-100">
                <button type="submit" class="update-plan-btn w-100">
                  Update Training
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadNewTrainingSession;
