import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useState } from "react";
import video from "../Image/video.mp4";

const Visitorpeople = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [MainData, setMainData] = useState(null);
  const handleVisit = (data) => {
    toast
      .promise(POSTAPI(Api.Visitor, data), {
        pending: "Wait Few Moment !",
        success: "Thank For Visiting !",
        error: "Something Went Wrong !",
      })
      .then((val) => {
        setMainData((prev) => val.data);
      })
      .catch((er) => {
        console.log(er);
      });
    reset();
  };
  return (
    <div className="asside-two">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/Home"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Visitor Details</h4>
            </div>
          </div>
        </div>
        {MainData ? (
          <div className="visit-50 profile-section-back-design py-main-100">
            <div className="d-flex justify-content-end  align-items-center p-4 ">
              <Link
                className="btn-close  "
                data-bs-toggle="modal"
                data-bs-target="#exitModal"
              ></Link>
            </div>
            <div className="d-flex justify-content-center ">
              <video controls autoPlay className="w-75 h-75">
                <source src={MainData?.video} />
              </video>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center visit-50 profile-section-back-design py-main-100">
            <div className="col-8">
              <form onSubmit={handleSubmit(handleVisit)} className="row ">
                <div class="col-6 mb-3">
                  <label for="inputEmail4" class="form-label">
                    Visitor Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputtext4"
                    placeholder="hello"
                    {...register("name", { required: true })}
                  />
                  {errors?.name && (
                    <p className="p-1 " style={{ color: "red" }}>
                      Plase Enter Name !
                    </p>
                  )}
                </div>
                <div class="col-6 mb-3">
                  <label for="inputnumber4" class="form-label">
                    Contact Number
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="inputnumber4"
                    placeholder="9998877887"
                    {...register("contact", { required: true })}
                  />
                  {errors?.contact && (
                    <p className="p-1 " style={{ color: "red" }}>
                      Plase Enter Contact !
                    </p>
                  )}
                </div>
                <div class="col-6 mb-3">
                  <label for="inputAddress" class="form-label">
                    Copmpany Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    placeholder="xyz"
                    {...register("companyName", { required: true })}
                  />
                  {errors?.companyName && (
                    <p className="p-1 " style={{ color: "red" }}>
                      Plase Enter Company Name !
                    </p>
                  )}
                </div>
                <div class="col-6 mb-3">
                  <label for="inputAddress2" class="form-label">
                    Company Website
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress2"
                    placeholder="xyz.com"
                    {...register("companySite", { required: true })}
                  />
                  {errors?.companySite && (
                    <p className="p-1 " style={{ color: "red" }}>
                      Plase Enter Company Site !
                    </p>
                  )}
                </div>
                <div class="col-12 mb-3">
                  <label for="inputCity" class="form-label">
                    Visiting Purpose
                  </label>
                  <textarea
                    rows="4"
                    type="text"
                    class="form-control"
                    id="inputCity"
                    placeholder="any one you type "
                    {...register("purpose", { required: true })}
                  />
                  {errors?.purpose && (
                    <p className="p-1 " style={{ color: "red" }}>
                      Plase Enter Purpose!
                    </p>
                  )}
                </div>
                <div class="col-12 mb-3">
                  <button
                    type="submit"
                    class="btn btn-primary next-visitor-btn"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div
        class="modal fade "
        id="exitModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered  ">
          <div class="modal-content spl-modal-body">
            <div className="close-modal-btn">
              <Link
                type="button"
                id="closeModal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-xmark"></i>
              </Link>
            </div>
            <div class="modal-body">
              <h5 className="text-center ">Are Sure to exit ?</h5>
              <div className="d-flex gap-3 align-items-center ">
                <Link
                  onClick={() => {
                    document.getElementById("CloseModal").click();
                  }}
                  to={"/Signin"}
                  type="submit"
                  className="emebtnss mx-auto "
                >
                  Exit
                </Link>
                <button
                  id="CloseModal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="emebtnss mx-auto "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visitorpeople;
